.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  /* height: 100%; */
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.page {
  overflow: auto;
  padding: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  
}
.page.top-gap{
  padding-top: 56px;
}

.page.bottom-gap{
  padding-bottom: 56px;
}
.cart-snap {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
}
/* ---- */

.logo-badge {
  display: block;
  margin: 15px auto;
  width: 88px;
}
button {
  white-space: nowrap;
}
.btn-block {
  display: block !important;
  margin: 0 auto;
  width: 100%;
}.btn-center {
  display: block !important;
  margin: 0 auto !important;
}

.btn-sm{
  font-size: 12px !important;
  padding: 2px 5px !important;
}

/* Typography --  */

h1 {}
h2 {
  font-size: 30px;
  font-weight: 600;
}
h3 {}

/* ------------ */
.code-container {
  padding-top: 56px;
}
.select-code {
 
}
.code-container .MuiList-root {

}


.code-container .MuiList-root .MuiButtonBase-root{

}
.code-container .MuiList-root .MuiButtonBase-root .MuiListItemText-root {
  margin: 0;
}

.code-container .MuiList-root .MuiButtonBase-root .MuiListItemText-root span {
  color: var(--login-text);
  font-size: 14px;
  font-family: inherit;
}

.code-container .MuiList-root .MuiButtonBase-root .MuiListItemText-root p {
  color: var(--login-text);
  font-size: 14px;
  font-family: inherit;
  opacity: 0.5;
}

.select-code .code-header{ 
  display: flex;
  align-items: center;
  height: 56px;
  color: var(--login-text);
  background:var(--login-bg);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
}
.filter-field {
  flex-grow: 1;
  padding-left: 15px;
}

.filter-field input {
  width: 100%;
  border: 1px solid var(--login-text);
  border-radius: 5px;
  color: var(--login-text);
  height: 36px;
  padding: 2px 5px;
  font-family: inherit;
  background-color: transparent;
  font-size: 16px;
}
/* ------- */
.phone-number-row {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
  width: 280px;
  border-bottom: 1px solid var(--login-text);
}
.phone-number-row .field input {
  width: 100%;
  color: var(--login-text);
  background: transparent;
  border: 0;
  height: 42px;
  font-size: 16px;
}
.phone-number-row .field.countryCode {
  width: 70px;
}
.phone-number-row .field.mobile {
  flex-grow: 1;
}

/* dialog */

body .MuiDialog-paper {
  background-color: var(--login-bg);
  color: var(--login-text);
  font-family: inherit;
}

body .MuiDialogTitle-root {
  padding: 8px 10px;
  text-align: center;
  border-bottom: 1px solid var(--primary-400);
  color: inherit;
  font-family: inherit;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
}

body .MuiDialogTitle-root h2 {
  color: inherit;
  font-family: inherit;
  font-weight: 400;
}

.MuiDialogContent-root{
  padding: 10px !important;
  
  text-align: center;
}
.MuiDialogContent-root.text{
  text-align: left;
}

.MuiDialogContent-root p {
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  font-weight: 100;
}


.MuiDialogActions-root{
  flex-wrap: wrap;
  justify-content: center !important;
}

.MuiDialogActions-root button{
  margin-bottom: 8px ;

}


